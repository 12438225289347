<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>领券页设置</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table"
           style="width: 50%; margin-top: 10px">
        <el-form label-width="100px">
          <el-form-item label="标题">
            <el-input v-model="title"></el-input>
          </el-form-item>
          <el-form-item label="领劵字段">
            <el-input v-model="field"></el-input>
          </el-form-item>
          <el-form-item label="主图"
                        prop="mainPic">
            
            <ImgUpload v-model="fileList"/>
          </el-form-item>
          <el-form-item label="背景色">
            <el-input v-model="backGroundColor"></el-input>
          </el-form-item>
          <el-form-item label="列表项链接">
              <el-input v-model="links" type="textarea" placeholder="请输入https优惠券跳转长链接，多链接用英文逗号隔开，顺序和优惠券图片保持关联"></el-input>
          </el-form-item>
          <el-form-item label="列表项图片">
              
               <ImgUpload v-model="fileList2"/>
          </el-form-item>
          <el-form-item label="子入口链接">
              <el-input v-model="childLinks" type="textarea" placeholder="请输入https优惠券跳转长链接，多链接用英文逗号隔开，顺序和优惠券图片保持关联"></el-input>
          </el-form-item>
          <el-form-item label="子入口图片">
              <ImgUpload v-model="fileList3"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btnAdd" @click="addGoods()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

  </div>
</template>

<script>
import ImgUpload from '../common/ImgUpload/index.vue'
export default {
  components: { ImgUpload },
  data () {
    return {
      title: '',
      field: '',
      links: '',
      childLinks: '',
      url: '',
      mainPic: '',
      backGroundColor: '',
      fileList: [],
      fileList2: [],
      fileList3: [],
      // 声明一个图片的地址
      uploadURL: '/api/file/uploadFile',
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },
      //图片地址
      dialogImageUrl: null,
      // 控制图片的显示隐藏
      isEdit: false
    }
  },
  created () {
    const {query} = this.$route;
    if(query.id){
      this.groupInfo = localStorage.getItem('welfare-' + query.id);
      if(this.groupInfo){
        this.groupInfo = JSON.parse(this.groupInfo);

        this.isEdit = true;
        this.title = this.groupInfo.title;
        this.field = this.groupInfo.field;
        this.backGroundColor = this.groupInfo.backGroundColor;
        this.fileList = this.groupInfo.pic.split(',')
        const list = JSON.parse(this.groupInfo.url);
        const arr1 = [], arr2=[];
        list.map(o => {
          arr1.push(o.pic);
          arr2.push(o.link);
        })
        this.fileList2 = arr1;
        this.links = arr2.join(',')
        
        const childList = JSON.parse(this.groupInfo.childUrl);
        const arr3 = [], arr4=[];
        childList.map(o => {
          arr3.push(o.pic);
          arr4.push(o.link);
        })
        this.fileList3 = arr3;
        this.childLinks = arr4.join(',')

      }
    }
    
  },
  methods: {
   
    // 添加商品
    addGoods () {
      //  预校验
      if (!this.title) return this.$message.error('请输入标题')
      if (!this.field) return this.$message.error('请输入领劵字段')
      if (!this.fileList.length) return this.$message.error('请上传主图')
      if (!this.fileList2.length) return this.$message.error('请上传列表项图片')
      const linksArr = this.links.split(',')
      const urls = this.fileList2.map((o, index) => {
        return {
          pic: o,
          link: linksArr[index] || ''
        }
      })
      const childLinksArr = this.childLinks.split(',')
      const childUrls = this.fileList3.map((o, index) => {
        return {
          pic: o,
          link: childLinksArr[index] || ''
        }
      })
      const params = {
        title:this.title,field: this.field,
        pic: this.fileList[0],
        url: JSON.stringify(urls),
        backGroundColor: this.backGroundColor,
        childUrl: JSON.stringify(childUrls),
      }
      let promise = null;
      if(!this.isEdit){
        promise = this.$api.AddWelfare(params)
      }else{
        params.id = this.groupInfo.id;
        promise = this.$api.UpdateWelfare(params)
      }
      promise.then((res) => {
        if (res.code !== 200) {
          this.$message.error('保存失败')
        } else {
          this.$message.success('保存成功')
          this.$router.push('/welfare')
        }
      })
      
    },

  },
}
</script>

<style lang="scss" scoped></style>
